.page-resident-register {
  background-color: #fff;
}
.page-resident-register .content-container {
  position: relative;
}
.page-resident-register .main-content {
  width: 100%;
  max-width: 375px;
  margin-right: auto;
  margin-left: auto;
  text-align: center;
  width: 100%;
  padding: 15px;
}
.page-resident-register .content-container .logo {
  margin: 40px auto;
  width: 150px;
  height: 40px;
}
.page-resident-register .main-content form input {
  font-size: 14px;
  line-height: 20px;
  padding: 0 15px;
}
.page-resident-register .main-content form .item-checkbox ion-checkbox {
  margin-right: 0;
}
.page-resident-register .main-content form .item-checkbox ion-label {
  font-size: 12px;
}
.page-resident-register .main-content form ion-label.label-floating {
  font-size: 14px;
  margin-top: 0;
}
.page-resident-register .main-content ion-item {
  margin-bottom: 15px;
}
.page-resident-register .main-content form ion-item:not(.item-has-focus):not(.item-has-value)  ion-label.label-floating {
  transform: translate3d(0, 15px, 0);
}
.page-resident-register .main-content ion-item:not(.item-has-focus):not(.item-has-value) ion-label {
  margin-left: 15px;
}
.page-resident-register .main-content ion-item::part(native) {
  padding-left: 0;
}
.page-resident-register .main-content ion-item.item-link::part(native) {
  font-size: 12px;
  padding-left: 40px;
  --min-height: 22px;
  --border-color: transparent;
}
.page-resident-register .main-content form .item-checkbox::part(native) {
  --border-color: transparent;
}
.page-resident-register .main-content form ion-label ion-note {
  display: block;
}
.page-resident-register .main-content form ion-label ion-note[color="danger"] {
  color: var(--ion-color-danger-tint);
}
.page-resident-register .main-content form ion-button[type="submit"] {
  width: 120px;
  height: 32px;
  padding: 0;
}
.page-resident-register .main-content form .form-action {
  margin-top: 28px;
}
.page-resident-register .forgot-password-container {
  margin-top: 5px;
  text-align: right;
  font-size: 10px;
}
.page-resident-register ion-list {
  --ion-background-color: transparent;
}
.page-resident-register .main-content ion-item ion-select {
  padding-top: 0;
  padding-bottom: 0;
  font-size: 14px;
}
.page-resident-register .item-label-floating {
  --min-height: 0;
}