.content-resident-sizeprice {
  --padding-top: 15px;
  --padding-start: 15px;
  --padding-end: 15px;
  --padding-bottom: 15px;
}
.content-resident-sizeprice .group-text-icon {
  text-align: center;
  color: var(--ion-color-dark-tint);
  font-size: 10px;
  line-height: 18px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.content-resident-sizeprice .group-text-icon > *{
  flex: 0 0 100%;
  text-align: center;
}
.content-resident-sizeprice .group-text-icon .icon-circle {
  margin-right: auto;
  margin-left: auto;
}
.content-resident-sizeprice .group-text-icon svg {
  font-size: 16px;
}
.content-resident-sizeprice .bottom-buttons {
  display: flex;
  justify-content: space-between;
}