body {
  font-weight: 400;
  color: var(--ion-text-color-rgb);
  background: linear-gradient(90deg, rgba(133, 199, 242, 1) 0%, rgba(19, 29, 35, 1) 100%);
}
.guest-screen-bg ion-content::part(background) {
  background: url(/public/assets/images/Buckingham_Square-Slideshow-06-optimized.jpg) no-repeat center center;
  background-size: cover;
}
.img-faceid {
  width: 24px;
  height: 24px;
}
.select-icon {
  margin-right: 10px;
}

/* button */
ion-button {
  font-weight: 300;
}
ion-button.button-small {
  padding-right: 38px;
  padding-left: 38px;
  height: 32px;
  border-radius: 40px;
}
ion-button[color="primary"]::part(native) {
  color: rgb(var(--ion-text-color-rgb));
}
ion-button.button-fullwidth {
  width: 100%;
}

/* fonts */
.fs-sx {
  font-size: 10px;
}

hr {
  background: rgb(101, 101, 101);
}

a:active {
  color: var(--ion-color-primary);
}
a {
  color: var(--ion-color-primary);
  text-decoration: none;
}

/* margin */
.mt {
  margin-top: 15px;
}
.mt-2 {
  margin-top: 25px;
}
.mt-3 {
  margin-top: 35px;
}
.mt-4 {
  margin-top: 45px;
}
.mt-5 {
  margin-top: 55px;
}
.no-mb {
  margin-bottom: 0;
}
.no-mb-i {
  margin-bottom: 0 !important;
}
.no-ml-i {
  margin-left: 0 !important;
}

/* padding */
.no-pr-i {
  padding-right: 0 !important;
}
.no-pl-i {
  padding-left: 0 !important;
}

/* table */
.table-grid {
  border-top: 1px solid var(--ion-color-primary);
  border-bottom: 1px solid rgba(var(--ion-separator-color-rgba));
  padding-top: 4px;
  padding-bottom: 4px;
}
.table-grid ion-row:not(:last-child) {
  border-bottom: 1px solid rgba(var(--ion-separator-color-rgba));
}
.table-grid ion-col {
  font-size: 12px;
  line-height: 18px;
}
.table-grid ion-col.col-icon {
  display: flex;
  align-items: center;
}
.table-grid ion-col .icon-circle {
  width: 36px;
  height: 36px;
  background-color: var(--ion-color-dark-tint);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  border-radius: 50%;
}
.table-grid ion-col .col-label {
  color: var(--ion-color-primary);
  font-size: 8px;
  line-height: 12px;
}

/* spacing */
.np-cols ion-col {
  padding: 0;
}

.alert-radio-label.sc-ion-alert-ios,
.alert-radio-label.sc-ion-alert-md {
  font-size: 12px;
}
.alert-radio-label.sc-ion-alert-md {
  padding-inline-start: 35px;
  padding-inline-end: 15px;
}
.sc-ion-alert-md-h {
  --min-width: 280px;
}
.btn-icon-only {
  font-size: 20px;
}

.item-with-action {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
}
.item-with-action > * {
  align-self: self-end;
}
.item-with-action .col-input {
  flex: 0 0 80%;
}
.item-with-action .col-action {
  flex: 0 0 20%;
  text-align: right;
}

/* colors */
.light {
  color: var(--ion-color-light);
}
.dark {
  color: initial;
}

.list-lines-none {
  background: none;
}

.item.sc-ion-label-ios-h,
.item .sc-ion-label-ios-h {
  white-space: normal;
}
