.content-resident-gallery .content-resident-main {
  width: 100%;
  height: 100%;
  position: relative;
}
.content-resident-gallery .swiper {
  width: 100%;
  height: 100%;
}
.content-resident-gallery .slide-item-fullscreen {
  width: 100%;
  height: 100%;
  position: relative;
}
.content-resident-gallery .slide-item-fullscreen .img-bg {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center center;
  position: relative;
  z-index: 1;
}
.content-resident-gallery .slide-item-fullscreen .img-bg::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(19, 29, 35, 0.9);
}
.content-resident-gallery .slide-item-fullscreen img {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 100%;
  height: auto;
  border: 1px solid rgba(19, 29, 35);
  z-index: 2;
}
.content-resident-gallery .swiper-pagination {
  position: absolute;
  width: 100%;
  height: 4px;
  top: 12px;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
}
.content-resident-gallery .swiper-pagination-bullet {
  margin-right: 2px;
  margin-left: 2px;
  width: 24px;
  height: 4px;
  background-color: var(--ion-color-medium);
}
.content-resident-gallery .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: #fff;
}
.gallery-selector ion-select {
  --padding-top: 11px;
  --padding-bottom: 11px;
  font-size: 12px;
  background: rgb(19, 29, 35);
  color: var(--ion-color-medium)
}
