.page-resident-login .content-container {
    position: relative;
  }
  .page-resident-login .main-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    max-width: 375px;
    text-align: center;
    color: #fff;
    width: 100%;
    padding: 15px;
  }
  .page-resident-login .content-container .logo {
    width: 150px;
    height: 40px;
    position: absolute;
    bottom: 70px;
    left: 50%;
    transform: translateX(-50%);
  }
  .page-resident-login .main-content form input {
    color: #fff;
    font-size: 14px;
    line-height: 20px;
    padding-right: 15px;
    padding-left: 15px;
  }
  .page-resident-login .main-content form ion-label {
    color: #fff;
    font-size: 14px;
  }
  .page-resident-login .main-content ion-item:not(.item-has-focus):not(.item-has-value) ion-label {
    margin-left: 15px;
  }
  .page-resident-login .main-content ion-item::part(native) {
    padding-left: 0;
  }
  .page-resident-login .main-content form ion-label ion-note {
    display: block;
  }
  .page-resident-login .main-content form ion-label ion-note[color=danger] {
    color: var(--ion-color-danger-tint);
  }
  .page-resident-login .main-content form ion-button[type="submit"] {
    background-color: rgba(216, 216, 216, 0.1);
    border: 1px solid #fff;
  }
  .page-resident-login .main-content form .form-action {
    margin-top: 28px;
  }
  .page-resident-login .forgot-password-container {
    margin-top: 5px;
    text-align: right;
    font-size: 10px;
  }
  .page-resident-login .forgot-password-container ion-router-link {
    --color: #fff;
  }
  .page-resident-login ion-list {
    --ion-background-color: transparent;
  }