.header-main {
  background: linear-gradient(90deg, rgba(133, 199, 242, 1) 0%, rgba(19, 29, 35, 1) 100%);
}
.header-toolbar-main {
  --background: linear-gradient(90deg, rgba(133, 199, 242, 1) 0%, rgba(19, 29, 35, 1) 100%);
}
.header-toolbar-main ion-title {
  font-size: 14px;
  text-align: center;
  color: #fff;
}
.header-toolbar-main ion-title ion-router-link {
  color: inherit;
}
.header-title {
  display: flex;
  justify-content: center;
  align-items: center;
}
.header-title h4 {
  margin-top: 0;
}
.header-title h4 svg {
  font-size: 24px;
  margin-right: 4px;
}
.header-title-container {
  padding: 0 16px 12px;
  /*  */
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header-title-container ion-col {
  align-items: center;
  display: flex;
}
.header-title-container .col-header-title h4 {
  margin-bottom: 0;
}
.header-title-container .col-back {
  display: flex;
  align-items: center;
}
.header-title-container .btn-back {
  margin: 0;
  width: 24px;
  height: 24px;
  border-radius: 50%;
}
.header-title-container .btn-back svg {
  color: var(--ion-color-primary);
}
.header-title-container .btn-back::part(native) {
  padding: 0;
}
.header-title-container .spacer {
  display: inline-block;
  width: 24px;
  height: 24px;
}
.header-toolbar-main .btn-home::part(native) {
  text-transform: initial;
  font-size: 16px;
}
