.content-resident-about {
  --padding-top: 15px;
  --padding-start: 15px;
  --padding-end: 15px;
  --padding-bottom: 15px;
}
.content-resident-about .img-fb {
  width: 97px;
  height: 19px;
  margin-left: auto;
}