.page-refer-a-friend {
    background-color: #fff;
  }
  .page-refer-a-friend .content-container {
    position: relative;
    --ion-background-color: #fff;
  }
  .page-refer-a-friend .main-content {
    width: 100%;
    max-width: 375px;
    margin-right: auto;
    margin-left: auto;
    text-align: center;
    width: 100%;
    padding: 15px;
  }
  .page-refer-a-friend .content-container .logo {
    margin: 40px auto;
    width: 150px;
    height: 40px;
  }
  .page-refer-a-friend .main-content form input,
  .page-refer-a-friend .main-content form textarea {
    font-size: 14px;
    line-height: 20px;
  }
  .page-refer-a-friend .main-content form {
    padding-top: 10px;
  }
  .page-refer-a-friend .main-content form .item-checkbox ion-checkbox {
    margin-right: 0;
  }
  .page-refer-a-friend .main-content form .item-checkbox ion-label {
    font-size: 12px;
  }
  .page-refer-a-friend .main-content form ion-label.label-floating {
    font-size: 14px;
    margin-top: 0;
  }
  .page-refer-a-friend .main-content ion-item {
    margin-bottom: 15px;
  }
  .page-refer-a-friend .main-content form ion-item:not(.item-has-focus):not(.item-has-value) ion-label.label-floating {
    transform: translate3d(0, 15px, 0);
  }
  .page-refer-a-friend .main-content ion-item:not(.item-has-focus):not(.item-has-value) ion-label {
    margin-left: 15px;
  }
  .page-refer-a-friend .main-content ion-item::part(native) {
    padding-left: 0;
    --inner-padding-end: 0;
  }
  .page-refer-a-friend .main-content form .item-checkbox::part(native) {
    --border-color: transparent;
  }
  .page-refer-a-friend .main-content form .item-checkbox.w-divider::part(native) {
    border-width: var(--border-width);
    border-style: var(--border-style);
    border-color: var(--border-color);
    border-bottom: 1px solid var(--ion-item-border-color, var(--ion-border-color, var(--ion-color-step-250, #c8c7cc)));
  }
  .page-refer-a-friend .main-content form ion-label ion-note {
    display: block;
  }
  .page-refer-a-friend .main-content form ion-label ion-note[color="danger"] {
    color: var(--ion-color-danger-tint);
  }
  .page-refer-a-friend .main-content form .form-action {
    margin-top: 28px;
    display: flex;
    justify-content: center;
  }

  .page-refer-a-friend ion-list {
    --ion-background-color: transparent;
  }
  .page-refer-a-friend .main-content ion-item ion-select {
    padding-top: 0;
    padding-bottom: 0;
    font-size: 14px;
  }
  .page-refer-a-friend .item-label-floating {
    --min-height: 0;
  }
  .item-confirm-delete::part(native) {
    padding-inline-start: 0;
  }
  