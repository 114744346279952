.page-messages .message-item::part(native) {
  padding-right: 15px;
  padding-left: 15px;
}
.page-messages ion-text {
  font-size: 14px;
  line-height: 20px;
}
.page-messages .message-header .subject {
  font-weight: 600;
}
.page-messages .message-header .timestamp {
  text-align: right;
}
.page-messages .message-header .timestamp ion-text {
  font-size: 12px;
  color: var(--ion-color-secondary);
}
/* single message */
.page-message .main-content {
  padding-right: 15px;
  padding-left: 15px;
}
