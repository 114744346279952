.main-menu-content::part(scroll) {
  padding: 15px 11px;
}
.main-menu-content::part(background) {
  --background: rgb(var(--ion-bg-main-bg-color-rgb));
}
.main-menu-content .user-name {
  margin: 0;
  font-weight: 600;
}
.main-menu-content ion-list {
  margin-top: 12px;
}
.main-menu-content ion-list ion-item::part(native) {
  --background: rgb(var(--ion-bg-main-bg-color-rgb));
  --min-height: 32px;
  padding-left: 13px;
  font-size: 14px;
  line-height: 20px;
}
.main-menu-content ion-list ion-item {
  color: #fff;
}
.main-menu-content ion-list ion-item.ion-activated::part(native) {
  color: var(--ion-color-primary);
}
.main-menu-content ion-list ion-item svg {
  padding-right: 7px;
  font-size: inherit;
}
.main-menu-content hr {
  margin-right: -11px;
  margin-left: -11px;
  width: calc(100% + 22px);
}