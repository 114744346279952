.action-buttons-container {
  display: flex;
  margin-top: 35px;
  justify-content: space-between;
}
.action-buttons-container .ion-button.button-small {
  padding-right: 0;
  padding-left: 0;
}
.confirm-dialog-content::part(scroll) {
  display: flex;
  align-items: center;
}

