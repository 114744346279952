.sub-tabs {
  --ion-tab-bar-background: rgba(23, 23, 23, 0.9);
}
.sub-tabs ion-tab-bar{
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  height: 40px;
  overflow-x: auto;
}
.sub-tabs ion-tab-button {
  line-height: 16px;
  font-size: 12px;
}
.content-resident {
  position: relative;
  height: calc(100% - 78px);
}
.page-community .content-resident {
  height: calc(100% - 168px);
}
.content-resident-main {
  color: rgb(var(--ion-text-color-rgb));
}
.content-resident-main .content-title {
  margin-bottom: 0;
}
.content-resident-main .content-title h2 {
  line-height: 33px;
  margin-bottom: 0;
}
.content-resident-main .content-subtitle h5 {
  margin-top: 0;
  line-height: 24px;
}
.content-resident-main .group-text-icon {
  font-size: 14px;
  line-height: 18px;
  color: var(--ion-text-color2-rgb);
}
.content-resident-main .group-text-icon:not(:first-child) {
  margin-top: 10px;
}
.content-resident-main .group-text-icon a {
  color: var(--ion-text-color2-rgb) !important;
}
.content-resident-main .group-text-icon .group-icon {
  font-size: 12px;
  line-height: 12px;
}
.content-resident-main .group-text-icon .group-icon .text-icon {
  margin-left: 5px;
  color: var(--ion-color-primary);
  font-weight: 600;
}
.content-resident-main ion-grid.np {
  margin-top: 21px;
  padding: 0;
}
.content-resident-main ion-grid ion-col {
  padding-left: 0;
}
.content-resident-main .description {
  margin-top: 40px;
}