.page-resident-welcome ion-content::part(background) {
  background: linear-gradient(180deg, rgba(133, 199, 242, 1) 0%, rgba(19, 29, 35, 1) 100%);
}
.page-resident-welcome .content-container {
  position: relative;
}
.page-resident-welcome .main-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #fff;
  max-width: 375px;
}
.page-resident-welcome .main-content ion-button {
  width: 227px;
  height: 40px;
  margin-bottom: 0;
}
.page-resident-welcome .main-content ion-button:not(:first-of-type) {
  margin-top: 20px;
}
.page-resident-welcome .main-content ion-button::part(native) {
  border-radius: 40px;
  border: 1px solid #fff;
}
.page-resident-welcome .content-container .logo {
  width: 150px;
  height: 40px;
  position: absolute;
  bottom: 70px;
  left: 50%;
  transform: translateX(-50%);
}
