.page-communities .swiper-slide {
  width: 70vw !important;
}
.page-communities .swiper-slide .logo {
  margin: 0 auto 24px;
  height: 10vh;
  width: auto;
}
.page-communities .swiper-slide .logo::part(image) {
  display: block;
}
.page-communities .swiper-slide .img-bg-wrap .img-bg {
  position: relative;
}
.page-communities .swiper-slide .img-bg {
  width: 100%;
  height: 40vh;
  background-position: center center;
  background-size: cover;
}
.page-communities .swiper-slide .img-bg {
  color: #fff;
}
.page-communities .swiper-slide .img-bg .svg-inline--fa {
  margin-right: 5px;
  color: var(--ion-color-primary);
}
.page-communities .swiper-slide .img-bg .location {
  position: absolute;
  bottom: 8px;
  width: 100%;
  text-align: center;
}
.page-communities .swiper-slide .btn-wrap {
  text-align: center;
}
.page-communities .swiper-slide ion-button {
  white-space: break-spaces;
}
