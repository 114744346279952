.message-item::part(native) {
  padding-right: 15px;
  padding-left: 15px;
  --ion-safe-area-right: 0;
}
.message-item ion-text {
  font-size: 14px;
  list-style: 20px;
}
.message-item .message-header .subject {
  font-weight: 600;
}
.message-item-modal ion-card-subtitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.message-item .message-header .timestamp {
  text-align: right;
}
.message-item .message-header .timestamp ion-text {
  font-size: 12px;
  color: var(--ion-color-secondary);
}
.message-item .status-dot {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--ion-color-danger);
}
.message-item-modal-content::part(scroll) {
  display: flex;
  align-items: center;
}
.message-item-modal-content-body {
  max-height: 65vh;
  overflow-y: auto;
}
.message-item-modal-content-body img {
  width: 100% !important;
  height: auto !important;
}
