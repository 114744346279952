.page-welcome .content-container {
  position: relative;
}
.page-welcome .main-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 375px;
  width: 100%;
  text-align: center;
  color: #fff;
}
.page-welcome .main-content ion-button {
  width: 227px;
  height: 40px;
  margin-bottom: 0;
}
.page-welcome .main-content ion-button:not(:first-of-type) {
  margin-top: 20px;
}
.page-welcome .main-content ion-button::part(native) {
  border-radius: 40px;
  border: 1px solid #fff;
}
.page-welcome .main-content ion-button::part(native):hover,
.page-welcome .main-content ion-button::part(native):active,
.page-welcome .main-content ion-button::part(native):focus {
  background: var(--ion-color-primary);
  color: rgb(var(--ion-text-color-rgb));
}
.page-welcome .content-container .logo {
  width: 150px;
  height: 40px;
  position: absolute;
  bottom: 70px;
  left: 50%;
  transform: translateX(-50%);
}