.app-tabs {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 11;
  padding-top: 15px;
  padding-bottom: 15px;
  background-color: rgb(19, 29, 35);
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 81px;
}
.app-tabs .app-tab-item {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.app-tabs .group-button-icon {
  background-color: rgb(19, 29, 35);
  color: var(--ion-color-medium);
  font-size: 12px;
  line-height: 20px;
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  justify-content: center;
  height: 100%;
}
.app-tabs .group-button-icon .group-icon {
  flex: 0 0 100%;
  align-self: flex-end;
  position: relative;
}
.app-tabs .group-button-icon .group-icon svg.main-icon {
  font-size: 18px;
}
.app-tabs .group-button-icon .group-text {
  align-self: flex-start;
  line-height: 17px;
}
.app-tabs .group-button-icon .group-icon .icon-msg-notif {
  position: absolute;
  transform: translateX(-50%);
  font-size: 6px;
  z-index: 2;
  color: var(--ion-color-danger);
}
